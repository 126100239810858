// src/components/Loader/Loader.js
import React, { useEffect, useState } from 'react';
import './Loader.css'; // Ensure you have the CSS for styling

const Loader = ({ onLoadComplete }) => {
  const [isVisible, setIsVisible] = useState(true);
  const letters = "UnseenModelAgency".split(""); // Split the text into an array of letters

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onLoadComplete(); // Call the function passed from the parent
    }, 3000); // Show for 3 seconds

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [onLoadComplete]);

  return (
    isVisible && (
      <div className="loader">
        {letters.map((letter, index) => (
          <span key={index} className="letter" style={{ animationDelay: `${index * 0.1}s` }}>
            {letter}
          </span>
        ))}
      </div>
    )
  );
};

export default Loader;
