// src/components/Service/Service.js
import React from 'react';
import './Service.css'; // Import any styles if needed

const Service = () => {
  return (
    <div className="service">
      <h1>Our Services</h1>
      <p>Here you can list the services offered by the Unseen Model Agency.</p>
      {/* Add more content here about your services */}
    </div>
  );
};

export default Service;
