import { Suspense, lazy, useState, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Navbar from './components/Nav/Nav';
import Loader from './components/Loader/Loader';
import Service from './components/Service/Service';

const Home = lazy(() => import('./components/Home/Home'));
const About = lazy(() => import('./components/About/About'));
const Blog = lazy(() => import('./components/Blog/Blog'));
const Gallery = lazy(() => import('./components/Gallery/Gallery'));
const Contact = lazy(() => import('./components/Contact/Contact'));

// Lazy loading new pages
const AllServicesPage = lazy(() => import('./components/AllServicesPage/AllServicesPage'));
const AllBlogPage = lazy(() => import('./components/AllBlogPage/AllBlogPage'));
const AllGalleryPage = lazy(() => import('./components/AllGalleryPage/AllGalleryPage'));

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const handleLoadComplete = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
  }, [location]);

  useEffect(() => {
    document.body.style.overflow = isLoading ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isLoading]);

  return (
    <>
      {isLoading && <Loader onLoadComplete={handleLoadComplete} />}
      <Navbar />
      <Suspense fallback={null}>
        <Routes location={location} key={location.key}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Service />} />
          <Route path="/all-services" element={<AllServicesPage />} />
          <Route path="/all-blogs" element={<AllBlogPage />} />
          <Route path="/all-gallery" element={<AllGalleryPage />} />
          
          {/* Catch-All Route to Redirect to Home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
