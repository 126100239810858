import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Nav.css';
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome CSS

const Navbar = () => {
  const location = useLocation(); // Get the current location

  return (
    <nav className="navbar">
      <div className="logo">
        <h1>Unseen Model Agency</h1>
      </div>

      {/* Social Media Icons for Desktop */}
      <div className="social-icons desktop-only">
        <a
          href="https://wa.me/256705973257" // Replace with your WhatsApp number
          target="_blank"
          rel="noopener noreferrer"
          aria-label="WhatsApp"
        >
          <i className="fa fa-whatsapp"></i>
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100083026556127&mibextid=LQQJ4d"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <i className="fa fa-facebook"></i>
        </a>
        <a
          href="https://www.tiktok.com/@unseenmodelagency?_t=8qqC4pkF0GT&_r=1"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="TikTok"
        >
          <i className="fa fa-tiktok"></i>
        </a>
        <a
          href="https://www.instagram.com/husse_in6923/profilecard/?igsh=MTh2cXpkN3ViOGRmYw=="
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <i className="fa fa-instagram"></i>
        </a>
      </div>

      {/* Desktop Navigation */}
      <div className="desktop-nav">
        <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link>
        <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About</Link>
        <Link to="/all-services" className={location.pathname === '/all-services' ? 'active' : ''}>Services</Link>
        <Link to="/all-blogs" className={location.pathname === '/all-blogs' ? 'active' : ''}>Blog</Link>
        <Link to="/all-gallery" className={location.pathname === '/all-gallery' ? 'active' : ''}>Gallery</Link>
        <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>Contact</Link>
      </div>

      {/* Mobile Navigation */}
      <div className="mobile-nav">
        <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
          <i className="fa fa-home" aria-label="Home"></i>
        </Link>
        <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>
          <i className="fa fa-info-circle" aria-label="About"></i>
        </Link>
        <Link to="/all-services" className={location.pathname === '/all-services' ? 'active' : ''}>
          <i className="fa fa-cogs" aria-label="Services"></i>
        </Link>
        <Link to="/all-blogs" className={location.pathname === '/all-blogs' ? 'active' : ''}>
          <i className="fa fa-pencil" aria-label="Blog"></i>
        </Link>
        <Link to="/all-gallery" className={location.pathname === '/all-gallery' ? 'active' : ''}>
          <i className="fa fa-image" aria-label="Gallery"></i>
        </Link>
        <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>
          <i className="fa fa-phone" aria-label="Contact"></i>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
